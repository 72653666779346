 
  <template>
    <b-navbar centered  mobile-burger="Menu" >
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
            
            </b-navbar-item>
      
        <template #start>
            <b-navbar-item href="#" style="font-size:22px;">
                INICIO
            </b-navbar-item>
            <b-navbar-item href="https://encuentratuhogarideal.com/" target="_blank" style="font-size:22px;">
                ETHI INMUEBLES
            </b-navbar-item>
            <b-navbar-item href="https://encuentratuhogarideal.com/RuralHomes" target="_blank" style="font-size:22px;">
              ETHI VACACIONES
            </b-navbar-item>         
        </template>

        <template #end>
            <b-navbar-item tag="div">
                <div class="buttons">
                    <button @click="registro" class="button is-danger">
                        <strong>Registrarse</strong>
                    </button>
                    <a  href="https://ethi-profesionales.netlify.app/#/" target="_blank" class="button is-light">
                        Acceder
                    </a>
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>
  </template>
  <script>  
  import { useRouter } from 'vue-router'
  export default {
    name:"MenuApp",
    setup() {
      const router = useRouter()
      
      async function registro()
      {
        router.push({name: 'Tarifas'})
      }
  
      // Retornar valores y funciones al template
      return {
        registro
      };
    },
  };
  </script>
  
  <style scoped>
 
  </style>
  