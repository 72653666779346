import {ref} from 'vue'
import ethiApi from '../api/ethiApi'
import { useMiStore } from '@/store/store';
const useSeguridad = () =>{
const token=ref('')
const store = useMiStore();   
 

async function generateJwtToken() {
  var response=await ethiApi.get("/simbolos/ficha")
  console.log(response)
  token.value=response.data
  store.setToken(token.value)
}

return{
    generateJwtToken
 }
}

export default useSeguridad