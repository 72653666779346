<template>
  <div class="row"  >
    <div class="col-lg-2 col-md-2 col-sm-12" style="padding-left:2%; ">
      <img src="./assets/img/LogoETHIServicios.png" @click="inicio"
      alt="Logo de Encuentra tu Hogar Ideal Servicios"/>
    </div>
    <div class="col-lg-10 col-md-10 col-sm-12" style="padding-right:25px;">
      <Menu/>
    </div>
  
  </div>
  <div class="container" style="margin-top:2%;min-height:600px;">
  <router-view/>
  </div>
  <div class="row">
    <div class="col-12">
      <hr style="height:5px;background-color:#1694DC;margin-left:2%;margin-right:2%;"/>
    </div>
  </div>
  <div class="row" style="padding-left:2%;padding-right:2%;padding-bottom:2%;margin-bottom:2%;">
    <div class="col-12">
 
            <div class="row">
              <div class="col-sm-4">
                  <ul style="text-align:left;padding-left:10%;">
                    <li><strong style="font-size:22px;color:black;"> Información para Profesionales</strong></li>
                    <li style="font-size:18px;color:black;cursor: pointer;" @click="registro"> Nuestras Tarifas</li>
                    <li style="font-size:18px;color:black;cursor: pointer;" @click="guia">Guia para el registro</li>
                    <li style="font-size:18px;color:black;cursor: pointer;" @click="registro"> Registrate en nuestro portal</li>
                  </ul>
              </div>
              <div class="col-sm-4">
               
              </div>
              <div class="col-sm-4">
                <ul style="text-align:left;padding-left:10%;">
                  <li><strong style="font-size:22px;color:black;">Sobre ETHI-Servicios</strong></li>
                  <li style="font-size:18px;color:black;cursor: pointer;" @click="quienesSomos">Quienes somos?</li>
                  <li style="font-size:18px;color:black;cursor: pointer;" @click="contacta">Contacta</li>
                
                  
                </ul>
              </div>
            </div>
 
    </div>
  </div>
</template>
<script>
import Menu from '/src/components/menu/Menu.vue'
import { useRouter } from 'vue-router'
import useSeguridad from '@/composables/Seguridad.js'
import {onMounted} from 'vue'


export default {
  components: {
    Menu
},
setup(){
  const router = useRouter()
  const {generateJwtToken}=useSeguridad()
  onMounted(() => {
    generateJwtToken();
    
  })
  async function registro()
  {
    router.push({name: 'Tarifas'})
  }

  async function guia()
  {
    router.push({name:'AyudaRegistro'})
  }

  async function quienesSomos()
  {
    router.push({name:'QuienesSomos'})
  }
  async function inicio()
  {
    router.push({name:'home'})
  }

  async function contacta()
  {
    router.push({name:'Contacto'})
  }

  return{
    registro,guia,quienesSomos,contacta,inicio
  }
}
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
