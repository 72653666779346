import { defineStore } from 'pinia';
export const useMiStore = defineStore('miStore', {
    state: () => ({
      profesionalId: 0,
      codigoPostal:{},
      token:''
    }),
    actions: {
      setProfesional(nuevoValor) {
        this.profesionalId = nuevoValor;
      },
      setCodigoPostal(codPostal){
        this.codigoPostal=codPostal;
      },
      setToken(t)
      {
        this.token=t;
      }
    },
    getters: {
      obtenerEstado: (state) => state.profesionalId,
      obtenerCodigoPostal:(state)=>state.codigoPostal,
      obtenerToken:(state)=>state.token
    },
    persist: true 
  });