import {ref} from 'vue'
import ethiApi from '../api/ethiApi'
import { useMiStore } from '@/store/store';
import useSeguridad from '@/composables/Seguridad.js'
const useMaestras = () =>{

    const {generateJwtToken}=useSeguridad()
    const provincias=ref([]);
    const localidades=ref([]);
    const store=useMiStore();
    const loca=ref([])
    const prov=ref([])
    const tipoVivienda=ref([]);
    const subTipoViviendas=ref([])
    const tipoOperacion=ref([])
    const zonas=ref([])
    const servicio=ref({})
    const servicios=ref([])
    const antiguedades=ref([
       {id:1,nombre:"0 - 5 Años"},
       {id:2,nombre:"5 - 10 Años"},
       {id:3,nombre:"10 - 15 Años"},
       {id:4,nombre:"15 - 20 Años"},
       {id:5,nombre:"20+ Años"}

    ])
    const orientacion=ref([])

    const escalaCertificadoEnergetico=ref([])

    const certificadoEnergetico=ref([
        {id:0,nombre:"En tramite"},
        {id:1,nombre:"Si, tengo certificado energetico"}
      ])

    const getProvincias=async(id)=>{
        await generateJwtToken();
        var header={"Authorization" : "Bearer " + store.token};
        var configuration= {headers : header}; 
        if(id>0)
            {
                await ethiApi.get("/maestras/provincias?id="+id,configuration).then(response=>{    
                    provincias.value=response.data;
                
                })
            }else
            {
              await  ethiApi.get("/maestras/provincias",configuration).then(response=>{    
                    provincias.value=response.data;
                    provincias.value.forEach((item)=>prov.value.push(item.name))
                })
            }
    };

async function getServicio(id)
{
    await generateJwtToken();
    console.log(store.token)
    var header={"Authorization" : "Bearer " + store.token};
    var configuration= {headers : header}; 
    console.log(configuration)
   await ethiApi.get("/maestras/servicios/"+id,configuration).then(response=>{    
        servicio.value=response.data;
    })
}

async function getServicioXNombre(nombre)
{
    await generateJwtToken();
    console.log(store.token)
    var header={"Authorization" : "Bearer " + store.token};
    var configuration= {headers : header}; 
    console.log(configuration)
   await  ethiApi.get("/maestras/servicios/nombre/"+nombre,configuration).then(response=>{    
        servicio.value=response.data;
    })
}

    async function getZonas(id)
    {
        await generateJwtToken();
        var header={"Authorization" : "Bearer " + store.token};
        var configuration= {headers : header}; 
       await  ethiApi.get("/maestras/zonas/"+id,configuration).then(response=>{    
            zonas.value=response.data;
        })
    }

    async function getServicios()
    {
        await generateJwtToken();
        console.log("token "+store.token)
        var header={"Authorization" : "Bearer " + store.token};
        var configuration= {headers : header}; 
        ethiApi.defaults.headers.common["Access-Control-Allow-Origin"]="*"
       await  ethiApi.get("/maestras/servicios",configuration).then(response=>{    
            servicios.value=response.data;
        })
    }
    const getLocalidades=async(id,codigoPostal)=>{
        await generateJwtToken();
        var header={"Authorization" : "Bearer " + store.token};
        var configuration= {headers : header}; 
        if(codigoPostal>0)
            {
                await ethiApi.get("/maestras/localidades?provincia="+codigoPostal,configuration).then(response=>{    
                    localidades.value=response.data;
               })           
            }else
            {
               await ethiApi.get("/maestras/localidades?provinciaId="+id,configuration).then(response=>{    
                    localidades.value=response.data;
                }
                )
       }
    }

   
     const getTipoOperacion=async()=>{
        await generateJwtToken();
        var header={"Authorization" : "Bearer " + store.token};
        var configuration= {headers : header}; 
        await ethiApi.get("/maestras/tipoOperacion",configuration).then(response=>{
            tipoOperacion.value=response.data;
        })
     }

     const getSubTipoViviendas=async(id)=>{
        await generateJwtToken();
        var header={"Authorization" : "Bearer " + store.token};
        var configuration= {headers : header}; 
        await ethiApi.get("/maestras/subTipoViviendas?id="+id,configuration).then(response=>{    
            subTipoViviendas.value=response.data;
       })
     }

     const getCertificadoEnergetico=async()=>{
        await generateJwtToken();
        var header={"Authorization" : "Bearer " + store.token};
        var configuration= {headers : header}; 
        await ethiApi.get("/maestras/certificadoEnergetico",configuration).then(response=>{
            escalaCertificadoEnergetico.value=response.data;
       })     
    }
    
    const getOperaciones=async()=>{
        await generateJwtToken();
        var header={"Authorization" : "Bearer " + store.token};
        var configuration= {headers : header}; 
       await  ethiApi.get("/maestras/orientacion",configuration).then(response=>{
            orientacion.value=response.data;
        })
    }

 

    return{
      
        getProvincias,getLocalidades,provincias,localidades,tipoVivienda,subTipoViviendas,getTipoOperacion,tipoOperacion,getSubTipoViviendas,prov,loca,getServicio,getServicioXNombre,
        antiguedades,certificadoEnergetico,orientacion,escalaCertificadoEnergetico,getCertificadoEnergetico,getOperaciones,zonas,servicios,getZonas,getServicios,servicio
    }
}
export default useMaestras