import axios from 'axios'

//LOCAL - DESARROLLO
/*const ethiApi=axios.create({
    baseURL:"https://localhost:7112/api/"})*/

//PRE PRODUCCION
/*const ethiApi=axios.create({
        baseURL:"https://dev-ethi-services.azurewebsites.net/api/"})*/

 //PRODUCCION   
/*const ethiApi=axios.create({
        baseURL:"https://www.ethiservicios.es/api-ethiServicios/api/"})*/

const ethiApi = axios.create({
      //  baseURL: import.meta.env.VITE_API_BASE_URL, // Para Vite
         baseURL: process.env.VUE_APP_API_BASE_URL, // Para Vue CLI
        });        
    
ethiApi.defaults.headers.common["Access-Control-Allow-Origin"]="*"
export default ethiApi  