import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createPinia } from 'pinia';
import { OhVueIcon, addIcons } from "oh-vue-icons";
import piniaPersistedstate from 'pinia-plugin-persistedstate';
import { FaFlag, RiZhihuFill } from "oh-vue-icons/icons";
import 'bootstrap/dist/css/bootstrap.min.css';  // Importa el CSS de Bootstrap
import 'bootstrap';  
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'// Importa los sc
const pinia = createPinia();
pinia.use(piniaPersistedstate);
addIcons(FaFlag, RiZhihuFill);
createApp(App).component("v-icon", OhVueIcon).use(Buefy).use(pinia).use(store).use(ElementPlus).use(router).mount('#app')
