import {ref} from 'vue'
import ethiApi from '../api/ethiApi'
import { useRouter } from 'vue-router'
import { useMiStore } from '@/store/store';
import useSeguridad from '@/composables/Seguridad.js'
const useProfesionales = () =>{
    const router = useRouter();
    const store = useMiStore();
    const accion=ref(false)
    const {generateJwtToken}=useSeguridad()
    const profesional=ref(
        {
            "id": 0,
            "usuarioId": 0,
            "nombreEmpresa": "",
            "descripcion": "",
            "ubicacion": "",
            "autonomo": false,
            "empresa": true,
            "servicio24": false,
            "email": "",
            "nombre": "",
            "apellido": "",
            "direccion": "",
            "codigoPostal": "",
            "localidadesId": 0,
            "provinciaId": 0,
            "urlInstagram": "",
            "urlFacebook": "",
            "urlTwitter": "",
            "telefono":"",
            "whatsapp":"",
            "imagen":"",
            "url":"",
            "todaComunidad":false,
            "nif":'',
            "password":"",
            "aceptacionGdpr":false,
            "fechaAceptacionGDPR":'',
            "todaEspaña":false,
            "proyectos": [],
            "servicios": [],
            "localidades": [],
            "trabajos":[]
          })
    const servicio=ref({
        "id":0,
        "servicioId":0,
        "nombreServicio":'',
        "profesionalServicioId":0,
        "activo":false
    })

    const localidad=ref({
      "id": 0,
      "localidadId": 0,
       "localidad": ""
    })
    const proyecto=ref(
            {
            "id": 0,
            "categoria": "",
            "titulo": "",
            "descripcion": "",
            "fechaCreacion": "",
            "estado": 0
            }        
    )

      
    const profesionales=ref([])
    const Zonas=ref([])
    const Servicios=ref([])
    const configuracion=ref({
        id:0,
        nroPresupuesto:'',
        nroFactura:''
    })


    const getProfesionales=async(id,pag)=>{
        await generateJwtToken();
        var header={"Authorization" : "Bearer " + store.token};
        var configuration= {headers : header}; 
        await ethiApi.get("/profesionales/paged?reg=10&pag="+pag,configuration).then(response=>{    
            profesionales.value=response.data.result;
       })
    }

    const getPerfil=async(id)=>{
        await generateJwtToken();
        var header={"Authorization" : "Bearer " + store.token};
        var configuration= {headers : header}; 
        if(id==0)
            router.push('/');
        await ethiApi.get("/profesionales/"+id,configuration).then(response=>{    
            profesional.value=response.data;
            
           
       })
    }

    const getPerfilNombre=async(nombre)=>{
        var encodedName = encodeURIComponent(nombre);
        await generateJwtToken();
        var header={"Authorization" : "Bearer " + store.token};
        var configuration= {headers : header}; 
        if(nombre=="")
            router.push('/');
        await ethiApi.get(`/profesionales/perfil/nombre/${encodedName}`,configuration).then(response=>{    
            profesional.value=response.data;
            
           
       })
    }

    async function generateSHA512(text) {
        const encoder = new TextEncoder();
        const data = encoder.encode(text);
        
        // Utiliza el algoritmo SHA-512
        const hashBuffer = await crypto.subtle.digest('SHA-512', data);
        
        // Convierte el ArrayBuffer en una cadena hexadecimal
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        
        return hashHex;
      }

    const getProfesionalZonePaged=async(pag,reg,id,codigoPostal)=>{
        await generateJwtToken();
        var header={"Authorization" : "Bearer " + store.token};
        var configuration= {headers : header}; 
        await ethiApi.get("/profesionales/paged/zonas/"+pag+"/"+reg+"/"+id+"/"+codigoPostal,configuration).then(response=>{    
            profesionales.value=response.data;
       })
    }

    const getProfesionalLocalidadesPaged=async(pag,reg,id,localidad)=>{
        await generateJwtToken();
        var header={"Authorization" : "Bearer " + store.token};
        var configuration= {headers : header}; 
        await ethiApi.get("/profesionales/paged/localidad/"+pag+"/"+reg+"/"+id+"/"+localidad,configuration).then(response=>{
            profesionales.value=response.data.result;
       })
    }

    const getProfesionalProvinciasPaged=async(pag,reg,id,provincia)=>{
        await generateJwtToken();
        var header={"Authorization" : "Bearer " + store.token};
        var configuration= {headers : header}; 
        await ethiApi.get("/profesionales/paged/provincia/"+pag+"/"+reg+"/"+id+"/"+provincia,configuration).then(response=>{
            profesionales.value=response.data.result;
       })
    }

    const getProfesionalPaged=async(pag,reg,id)=>{
        await generateJwtToken();
        var header={"Authorization" : "Bearer " + store.token};
        var configuration= {headers : header}; 
        await ethiApi.get("/profesionales/paged/"+pag+"/"+reg+"/"+id,configuration).then(response=>{    
            profesionales.value=response.data;
            
           
       })
    }


    async function getConfiguracion(id)
    {
        await generateJwtToken();
        var header={"Authorization" : "Bearer " + store.token};
        var configuration= {headers : header}; 
        await ethiApi.get("/profesionales/configuracion/"+id,configuration).then(response=>{    
            configuracion.value=response.data;
            
           
       })
    }
    async function saveConfiguracion()
    {
        var conf=configuracion.value
        await generateJwtToken();
        var header={"Authorization" : "Bearer " + store.token};
        var configuration= {headers : header}; 
        await ethiApi.post("profesionales/configuracion/save",conf,configuration).then(response=>{            
            accion.value=response.data;
          
        }) 
    }
    
    async function save()
    {
        await generateJwtToken();
        var header={"Authorization" : "Bearer " + store.token};
        var configuration= {headers : header}; 
        const today = new Date();
        profesional.value.fechaAceptacionGDPR=today
        profesional.value.password=await generateSHA512(profesional.value.password);
        const prof=profesional.value;
        await ethiApi.post("profesionales",prof,configuration).then(response=>{   
            profesional.value.id=response.data;
            store.setProfesional(profesional.value.id)
            accion.value=true;
        }) 
        
    }

    return{
        getPerfil,save,profesional,accion,profesionales,getProfesionales,Zonas,getProfesionalPaged,getProfesionalLocalidadesPaged,getPerfilNombre,
        Servicios,configuracion,saveConfiguracion,getConfiguracion,localidad,proyecto,servicio,getProfesionalZonePaged,getProfesionalProvinciasPaged
    }
}
export default useProfesionales